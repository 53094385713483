import React, { createContext, useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  GET_ALL_VIEWS,
  GET_ALL_WORKSPACES,
  GET_NOTIFICATIONS,
  GET_TODOS,
} from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";

export enum MessageStatus {
  SUCCESS = "success",
  ERROR = "error",
}

interface EmailMessageType {
  code: MessageStatus;
  message: string;
}

interface RefetchContextType {
  allViews: any[];
  refetchViews: () => Promise<any>;
  emailMessage: EmailMessageType | undefined;
  setEmailMessage: React.Dispatch<
    React.SetStateAction<EmailMessageType | undefined>
  >;
  workspaces: any[];
  currentWorkspace: any;
  setCurrentWorkspace: React.Dispatch<React.SetStateAction<any>>;
  workspaceMembers: any[];
  notifications: any[];
  refetchWorkspaces: any;
  dueTodos: any[];
  allTags: Map<string, string[]>;
  setAllTags: React.Dispatch<React.SetStateAction<Map<string, string[]>>>;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollPosition: any;
  setScrollPosition: React.Dispatch<React.SetStateAction<any>>;
  atlasEnabled: boolean;
  setAtlasEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RefetchContext = createContext<RefetchContextType | null>(null);

const RefetchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { socket } = useContext(SocketIOContext) as any;

  const [emailMessage, setEmailMessage] = useState<
    EmailMessageType | undefined
  >(undefined);
  const [currentWorkspace, setCurrentWorkspace] = useState<any>(undefined);
  const [workspaceMembers, setWorkspaceMembers] = useState<any[]>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [allTags, setAllTags] = useState<Map<string, string[]>>(new Map());
  const [atlasEnabled, setAtlasEnabled] = useState(false);

  const {
    data: workspaces,
    loading: workspacesLoading,
    refetch: refetchWorkspaces,
  } = useQuery(GET_ALL_WORKSPACES, {
    variables: {
      query: { members_in: { auth0Sub: user?.sub }, isDeleted: false },
    },
    fetchPolicy: "network-only",
  });

  const { data: notifications } = useQuery(GET_NOTIFICATIONS, {
    variables: { query: { userId: { auth0Sub: user?.sub } } },
  });

  const { data: todosBuff, refetch: refetchTodos } = useQuery(GET_TODOS, {
    variables: { query: { userId: { auth0Sub: user?.sub }, isDeleted: false } },
  });

  const {
    data: views,
    loading: viewsLoading,
    refetch: refetchViews,
  } = useQuery(GET_ALL_VIEWS, {
    variables: {
      query: { workspaceId: currentWorkspace?._id, isDeleted: false },
    },
    skip: !currentWorkspace,
  });

  useEffect(() => {
    if (workspaces?.workspaces?.length > 0 && !currentWorkspace) {
      const workspaceId = localStorage.getItem("selectedWorkspace");
      const selected = workspaces.workspaces.find(
        (w: any) => w._id === workspaceId
      );
      setCurrentWorkspace(selected || workspaces.workspaces[0]);
      setAtlasEnabled(
        selected?.atlasEnabled ||
          workspaces.workspaces?.[0]?.atlasEnabled ||
          false
      );
    } else if (!workspacesLoading && workspaces?.workspaces?.length === 0) {
      if (window.location.pathname !== "/welcome/personal-details") {
        window.location.href = "/welcome/personal-details";
      }
    }
  }, [workspaces, currentWorkspace, workspacesLoading]);

  useEffect(() => {
    if (currentWorkspace) {
      setAtlasEnabled(currentWorkspace?.atlasEnabled || false);
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (currentWorkspace?._id) {
      setWorkspaceMembers(
        currentWorkspace.members?.filter((member: any) => member?.auth0Sub) ||
          []
      );
    }
  }, [currentWorkspace]);

  useEffect(() => {
    if (views?.views && socket) {
      views.views.forEach((v: any) => socket.emit("join-room", v._id));
    }
  }, [views, socket]);

  useEffect(() => {
    if (workspaces?.workspaces && socket) {
      workspaces.workspaces.forEach((w: any) =>
        socket.emit("join-workspace", w._id)
      );
    }
  }, [workspaces, socket]);

  useEffect(() => {
    if (user?.sub && socket) {
      socket.emit("join-user", user.sub);
    }
  }, [user, socket]);

  // useEffect(() => {
  //   refetchWorkspaces();
  //   refetchViews();
  //   refetchTodos();
  // }, [refetchWorkspaces, refetchViews, refetchTodos]);

  const dueTodos = todosBuff?.todos?.filter((todo: any) => {
    const dueDate = new Date(todo.dueDate);
    const today = new Date();
    return (
      (dueDate < today ||
        dueDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) &&
      todo.status === 1
    );
  });

  // User subscription check
  useEffect(() => {
    const createdDate = new Date(user?.createdAt);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - createdDate.getTime();
    const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;

    if (
      timeDifference > twoWeeksInMilliseconds &&
      user?.mercsubscriptionStatus !== "active" &&
      user?.mercsubscriptionStatus !== "trialing"
    ) {
      console.log(user?.mercsubscriptionStatus);
      window.location.href = "/pricing?trialExpired=true";
    }
  }, [user]);

  // Specific user redirect
  useEffect(() => {
    if (user?.sub === "auth0|65aeed99d5ad1c5d3702ac51") {
      window.location.href =
        "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley";
    }
  }, [user]);

  const contextValue: RefetchContextType = {
    allViews: views?.views || [],
    refetchViews,
    emailMessage,
    setEmailMessage,
    workspaces: workspaces?.workspaces || [],
    currentWorkspace,
    setCurrentWorkspace,
    workspaceMembers,
    notifications: notifications?.notifications || [],
    refetchWorkspaces,
    dueTodos: dueTodos || [],
    allTags,
    setAllTags,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    scrollPosition,
    setScrollPosition,
    atlasEnabled,
    setAtlasEnabled,
  };

  return (
    <RefetchContext.Provider value={contextValue}>
      {children}
    </RefetchContext.Provider>
  );
};

export default RefetchProvider;
