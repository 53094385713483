import React, { memo } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { GetHeaderText, GetSubheaderText } from "./DatagridFunctions";
import { getImageString } from "./highlightRow";

interface MapMarkerProps {
  row: any;
  columns: any[];
  latCol: any;
  lngCol: any;
  selectedRow: any;
  setSelectedRow: (row: any) => void;
  setShowSidebar: (show: boolean) => void;
  addressColumn: any;
  formatPhoneNumber: (phone: string) => string;
  stripPhoneNumber: (phone: string) => string;
  isPointInViewPort: (lat: number, lng: number) => boolean;
}

const MapMarker: React.FC<MapMarkerProps> = memo(
  ({
    row,
    columns,
    latCol,
    lngCol,
    selectedRow,
    setSelectedRow,
    setShowSidebar,
    addressColumn,
    formatPhoneNumber,
    stripPhoneNumber,
    isPointInViewPort,
  }) => {
    const lat = parseFloat(row[latCol?.field]);
    const lng = parseFloat(row[lngCol?.field]);
    const phoneCol = columns.find((col) => col?.type === "phone");
    const formattedNumber = formatPhoneNumber(row[phoneCol?.field]);
    const phoneToCall = stripPhoneNumber(row[phoneCol?.field]);
    const src = `https://maps.googleapis.com/maps/api/streetview?size=${250}x${140}&location=${lat},${lng}&key=AIzaSyBGRT9wxwnP_vbsbTE_HHUb55W5BQH4XZE`;
    const address = row[addressColumn?.field];
    const color = row["merc_Color"];
    const isSelected =
      lat === parseFloat(selectedRow?.[latCol?.field]) &&
      lng === parseFloat(selectedRow?.[lngCol?.field]);
    const isHighlighted = selectedRow?._id === row._id;

    return (
      <Marker
        key={row._id}
        position={{ lat, lng }}
        onClick={(e) => {
          e.domEvent.preventDefault();
          setSelectedRow(row);
        }}
        icon={{
          url: isHighlighted
            ? "/mapImages/mapsMarker_blue.svg"
            : getImageString(color),
          anchor: new google.maps.Point(17, 46),
        }}
      >
        {isSelected && isPointInViewPort(lat, lng) && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedRow(undefined);
            }}
          >
            <div style={{ maxWidth: "275px" }}>
              <img
                src={src}
                alt="streetview"
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  marginBottom: "4px",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#175CD3",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedRow(row);
                  setShowSidebar(true);
                }}
              >
                {GetHeaderText(row, columns)}
                {/* <NewIndivHeader row={row} columns={columns} /> */}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {GetSubheaderText(row, columns)}
              </Typography>
              <Typography
                sx={{ fontSize: "14px" }}
                onClick={() => {
                  setSelectedRow(row);
                  setShowSidebar(true);
                }}
              >
                {addressColumn?.field === "merc_Address" &&
                  row?.[addressColumn?.field]}
              </Typography>
              {!!formattedNumber && (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <PhoneIcon sx={{ marginRight: "4px", fontSize: "18px" }} />
                  <a href={`tel:${phoneToCall}`}>{formattedNumber}</a>
                </Typography>
              )}
              {row?.latestActivity?.commentId && (
                <div
                  style={{
                    padding: "8px",
                    border: "1px solid #E2E4E9",
                    borderRadius: "8px",
                    marginTop: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    {`${row?.latestActivity?.commentId?.userId?.name}`}{" "}
                    <span
                      style={{ color: "#475467", fontWeight: 400 }}
                    >{`${new Date(
                      row?.latestActivity?.timestamp
                    )?.toLocaleDateString()}`}</span>
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: row?.latestActivity?.commentId?.comment,
                    }}
                    className={"mapInfoBubble"}
                    style={{
                      margin: "0px",
                    }}
                  />
                </div>
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
);

export default MapMarker;
